#section-filtre-header {
  width: 100%;
  height: 10vh;
  color: #fff;
  background-color: #d3a229;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section-filtres {
  width: 100%;
  height: auto;
  background-color: rgba(242, 242, 242, 0.7);
  font-family: "Work Sans", sans-serif;
  padding: 25px !important;
}

.prix-label {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  display: block;
  margin-bottom: 1vh;
}
.options-additionnelles {
  margin-top: 3vh;
  margin-bottom: 2vh;
}
.filtre-lieu,
.filtre-date,
.choix-nombre,
.options {
  color: #090909;
}
.date {
  margin-bottom: 3vh;
  margin-top: 2vh;
}
.lieu-filtre {
  margin-top: 2vh;
}

.partie-checkbox {
  display: flex;
  justify-content: space-between;
}
#section-filtre-footer {
  width: 100%;
  height: 10vh;
  color: #fff;
  background-color: #d3a229;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: 3vh;
}
