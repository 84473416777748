#section-collection {
  display: flex;
  justify-content: center;
  width: 98%;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
}
#collection-pagination {
  display: flex;
  flex-direction: column;
}
#collections {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  height: auto;
  flex: 3;
  width: 100%;
}
#filtres {
  height: auto;
  width: 300px;
}

/*Responsivité*/

@media screen and (max-width: 992px) {
  #section-collection {
    flex-direction: column;
  }
  #filtres {
    width: 95% !important;
    margin: auto;
  }
  #collections {
    width: 90% !important;
    margin: auto;
  }
}

@media screen and (max-width: 640px) {
  .spanvide,
  .renitialise {
    margin-top: 30vh !important;
  }
}
