.containerLogin {
  display: flex;
  gap: 50px;

  width: 80%;
  margin: auto;
  height: auto;
  margin-top: 2vh;
}
#carte-auto {
  flex: 2;
  display: flex;
  justify-content: center;
  padding-top: 4vh;
}
.assurance,
.options-sup,
.details-client,
.mode-payement {
  margin-top: 3vh;
  font-weight: bold;
}
.form-details {
  margin-bottom: 5vh;
}
.checkboxes {
  display: flex;
  justify-content: space-between;
}
.checkbox-droite {
  display: flex;
  flex-direction: column;
}
#partie-options-sup {
  margin-bottom: 5vh;
}
#carte-credit {
  width: 100%;
  height: 30vh;
  background-color: #282c30;
  margin-top: 5vh;
  color: #fff;
  padding: 30px;
}
.details-carte {
  display: flex;
  justify-content: space-between;
  margin-top: 8vh;
}
#total-carte {
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;
  font-weight: bold;
}
#total-carte h5 {
  font-weight: bold;
  font-size: 25px;
}
#total-carte span {
  font-size: 18px;
}
#reservation-boutton {
  padding: 10px;
  background-color: #e2ad2b;
  text-align: center;
  max-width: 250px;
  margin-top: 3vh;
}
#reservation-boutton-partie {
  display: flex;
  justify-content: flex-end;
}
#reservation-boutton span {
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 640px) {
  .containerLogin {
    margin-top: 30vh;
  }
  .checkboxes {
    flex-direction: column;
  }
}
@media screen and (max-width: 992px) {
  .containerLogin {
    flex-direction: column;
  }
}
