


.formInputEdit .inputFieldEdit{
    margin-top: 10px;
    width: 100%;
    height: 50px;
    position: relative;
}

.inputFieldEdit select{
    width: 95%;
    height: 100%;
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px rgba(110, 118, 132, 0.33);
   
}

@media (max-width:650px){

    .inputFieldEdit select{
        width: 100%;
        height: 100%;      
    }

}