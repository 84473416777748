#search-bar {
  width: 100%;
  height: 10vh;
  background-color: rgba(0, 0, 0, 0.058);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #979797;
  font-family: "Lato", sans-serif;
}
.select-voiture {
  width: 25%;
  padding-bottom: 2vh;
}
.offre {
  font-weight: 500;
  font-size: 16px;
}
@media screen and (max-width: 640px) {
  .select-voiture {
    width: 60%;
    padding-bottom: 2vh;
  }
  .offre {
    font-size: 12px;
  }
  #search-bar {
    padding: 25px 5px;
  }
}
