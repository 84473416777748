._2Jtxm._35Ago ._2kL0S {
  background-color: #e2ad2b;
  color: white;
  animation: _JAh3L 400ms ease forwards;
}
._hsN1w {
  background-color: #e2ad2b;
  border: none;
  color: white;
}
._hsN1w:hover {
  background-color: #e2ad2b;
  border: none;
  box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);
}
