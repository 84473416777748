.content-103 {
  background: rgb(255, 255, 255);
  padding-bottom: 0px;
}
.contain_section {
  background-color: #f2f2f2;
  padding-bottom: 0px;
  padding: 20px;
  margin: 20px;
}

._download_apk {
  background-color: #ffffff;
  padding-bottom: 0px;
  padding: 20px;
  margin: 20px;
}

._download_apk p {
  width: 100%;
  line-height: 2.3;
}
.contain_section {
  background-color: white;
}
._image_section {
  width: 750px;
  height: 500px;
}
._paragraph h3 {
  font-family: "Montserrat";
}
._paragraph p {
  width: 80%;
  line-height: 2.3;
}
._download_paragraph {
  text-align: center;
  font-family: "Montserrat";
  padding: 15px;
}
.content-103 .sercies-title {
  text-align: center;
  padding: 50px 0;
}
.container_post {
  display: flex;
  padding: 20px;
  z-index: -1;
}
.social_ h4 {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
}
._input_download {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: "Montserrat";
}
.Icon_download {
  font-size: 50px;
  position: relative;
  left: 50px;
  top: 10px;
}
.Icon_download_l,
.Icon_download {
  cursor: pointer;
}
.social_ {
}
.Icon_download_l {
  font-size: 50px;
  position: relative;
  left: 80px;
  top: 14px;
}
._social_network {
  display: flex;
  justify-content: center;
  font-size: 40px;
}
._social {
  padding: 30px;
}
._input_l {
  padding: 20px 60px;
  width: 33%;
  border: 3px solid orange;
  border-radius: 4px;
  position: relative;
  left: -10px;
}

._input_r {
  padding: 20px 80px;
  width: 30%;
  border: 3px solid orange;
  border-radius: 4px;
  position: relative;
  left: 10px;
}
.service-post {
  background-color: #f2f2f2;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  -moz-box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
}
.content-103 .service-content {
  position: relative;
  z-index: 13;
}
.content-103 .service-icon {
  margin: 10px 0 20px 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  border: 2px solid orange;
  margin-left: auto;
  margin-right: auto;
  line-height: 50px;
  -moz-transition: color, 250ms;
  -o-transition: color, 250ms;
  -webkit-transition: color, 250ms;
  transition: color, 250ms;
}
.content-103 .service-post:hover .service-icon {
  border-color: #fff;
}
.content-103 .service-icon {
  font-size: 28px;
  color: orange;
}
.content-103 .service-post:hover .service-icon {
  color: #fff;
}
.content-103 .service-title {
  font-family: "Montserrat";
  font-weight: normal;
  margin-bottom: 15px;
  padding-top: 20px;
  -moz-transition: color, 250ms;
  -o-transition: color, 250ms;
  -webkit-transition: color, 250ms;
  transition: color, 250ms;
}
.row_about h2 {
  font-size: 30px;
}

.service-description {
  font-family: none !important;
  font-size: 16px;
  font-weight: normal;

  margin-bottom: 0px;
  -moz-transition: color, 250ms;
  -o-transition: color, 250ms;
  -webkit-transition: color, 250ms;
  transition: color, 250ms;
  text-align: center;
  padding: 0px 0;
}
.content-103 .service-post:hover .service-title {
  color: #fff;
}
.content-103 .service-description {
  -moz-transition: color, 250ms;
  -o-transition: color, 250ms;
  -webkit-transition: color, 250ms;
  transition: color, 250ms;
}
.content-103 .service-post:hover .service-description {
  color: #fff;
}
._download_apk p {
  line-height: 2;
}

.content-103 .service-hover {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;

  z-index: 11;
  width: 100%;
  height: 0px;
  background: orange;
  -moz-transition: width, 250ms;
  -o-transition: width, 250ms;
  -webkit-transition: width, 250ms;
  transition: width, 250ms;
}
/*hover-background-color effect*/
.content-103 .service-post:hover .service-hover {
  height: 100%;
}
.contain_section {
  display: flex;
}
@media screen and (max-width: 420px) {
  .container_post {
    display: block;
  }
  ._image,
  ._image_section {
    display: none;
  }
  .contain_section {
    position: relative;
    margin-top: 230px;
  }
  ._paragraph p {
    width: 80%;
    font-size: 20px;
    line-height: 1.3;
  }

  .row_about h2 {
    font-size: 16px;
  }
  .row_about {
    position: relative;
    top: 180px;
  }
  .social_ {
    position: relative;
    top: 20px;
    font-size: 14px;
    background-color: #f2f2f2;
    padding: 30px;
  }
  ._input_download {
    display: block;
  }
  ._input_l {
    display: none;
  }

  ._input_r {
    display: none;
  }
  .Icon_download_l {
    font-size: 27px;
    left: 30%;
    margin-left: 90px;
  }
  .Icon_download {
    font-size: 30px;
    left: 30%;
    margin-left: -13px;
  }
}
