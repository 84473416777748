
.pagePwd{
    display: grid;
    max-width: 50%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap:20px;
    margin: auto;
    background-color: #f2f2f2;
    padding: 30px;
    position: relative;
    top: 50px;
}


.formForget{
    width: 100%;
    position: relative;
}


.infoForget h2{
    font-family: 'Montserrat', sans-serif !important;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #090909;
}


.formForgetGroup{
    position: relative;
    top: 10px;
}

.formForgetGroup .inputFieldForget{
    margin-top: 10px;
    width: 100%;
    height: 50px;
    position: relative;
}

.inputFieldForget input{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px rgba(110, 118, 132, 0.33);
   
}

.inputFieldForget input:is(:focus, :valid){
    border:solid 1px #e2ad2b;
}

.formForgetGroup .button{
    margin-top: 20px;
}

.formForgetGroup .button input{
    border: none;
    background-color: #e2ad2b;
    color: white;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    cursor: pointer;
}

.signUpFormForget{
    text-align: center;
    margin-top: 20px;
}

.signUpFormForget a{
  color: #e2ad2b;
  font-size: 14px;
  font-family: 'WorkSans', sans-serif;
}

.signUpFormForget span{
    color: #090909;
    font-size: 14px;
    font-family: 'WorkSans', sans-serif;
  }

.signUpFormForget a:hover{
    text-decoration: underline;
    
  }

/* CSS MOBILE */
@media screen and (max-width: 650px) {

    .containerForgetPassword{
    position: relative;
    width: 100%;
    height: 100%;
    }


    .pagePwd{
        max-width: 100%;
        grid-template-columns: 1fr;
        position: relative;
        padding: 15px;
        top:140px;
    }

    .formForget{
        width: 100%;
        position: relative;
    }

    .infoForget h2{
        font-size: 19px;
    }
  
    .formForgetGroup .button input{
        font-size: 14px;
    }
}