.contactFormulaire {
  max-width: 80%;
  margin: auto;
  padding: 30px;
  position: relative;
  top: -20px;
}

.formContactGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}

.title-contact {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.GroupContact .inputFieldContact {
  margin-top: 10px;
  margin-bottom: 1vh;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputFieldContact input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.inputFieldContact input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

/* .GroupContact .button{
    margin-top: 40px;
} */

.formContactSubjet input {
  width: 100%;
}

.formContactMessage textarea {
  width: 100%;
  padding: 10px;
  line-height: 150%;
  resize: none;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

#form_button {
  background: none;
  border: solid 1px #47454466;
  color: #474544;
  cursor: pointer;
  display: inline-block;
  font-family: "Montserrat";
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  border-radius: 4px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  left: 25%;
  width: 50%;
}

#form_button:hover {
  background: orange;
  color: #f2f3eb;
}

.submit-contact {
  position: relative;
  top: 110px;
}

/* CSS MOBILE */
@media screen and (max-width: 650px) {
  .formContactGroup {
    display: block;
  }
  .GroupContact .inputFieldContact {
    margin-bottom: 2.8vh !important;
  }
  .contactFormulaire {
    max-width: 100%;
    grid-template-columns: 1fr;
    padding: 15px;
    top: 30vh;
  }

  .Title-Titre {
    width: 100%;
    position: relative;
  }

  .title-contact {
    font-size: 13px;
    text-align: center;
    margin-left: 0px;
    margin-right: 50px;
  }
}
