* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

/* body{
  background-color: black;
} */

h1,
h4,
p,
a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btnReservation {
  padding: 18px 32px;
  border-radius: 10px;
  background-color: #e2ad2b;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
