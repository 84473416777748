#reservationCard {
  width: 60%;
  height: auto;
  background-image: linear-gradient(to bottom, #222535, #090909);
  margin: 0 auto;
  position: relative;
  top: -10vh;
  font-family: "Work Sans", sans-serif;
  padding: 5px 30px 20px 30px;
}
.inputs-fields {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1vh;
}

.label-lieu,
.label-date,
.label-heure {
  display: block;
  color: #f2f2f2;
  font-size: 14px;
  font-weight: normal;
}
.date {
  flex: 1.5;
}
.heure {
  flex: 1;
}
.groupe-radios {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 3vh;
}
.btn-reserver {
  width: 300px !important;
  height: 8vh;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #e2ad2b;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectVoiture {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  width: 100%;
  flex-wrap: wrap;
}
@media screen and (max-width: 770px) {
  #reservationCard {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .boutton-reserver {
    margin-top: 3vh;
  }
  #service-chauffeur {
    margin-bottom: 4vh;
  }
  .heure,
  .date,
  #service-chauffeur {
    margin-top: 3vh;
  }
  .inputs-fields {
    display: block !important;
  }
  .lieu {
    display: block !important;
  }
  .selectVoiture {
    display: block;
  }
  .groupe-radios {
    display: block;
  }
}
