#nosAgences {
  width: 100%;
  min-height: 90vh;
  position: relative;
  background-image: linear-gradient(to top, #090909 114%, #2f2f2f -3%);
  padding: 10vh 0;
  color: white;
  margin-top: 10vh;
  top: 5vh;
}
.details-agences {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}
.titreAgences {
  font-weight: 500;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}
.tiret-droit,
.tiret-gauche {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.emplacements-agence {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin-top: 2vh;
  font-family: "Work Sans", sans-serif;
}
.information-agences {
  font-size: 18px;
  font-weight: 100;
  line-height: 3vh;
}
.lieux {
  font-size: 18px;
  font-weight: bold;
}

.recherche-agence {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  gap: 2vh;
  margin-top: 4vh;
}
.champ-recherche {
  flex: 3;
  background-color: #fff;
  max-width: 600px;
  border-radius: 1px;
  height: 7vh;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0 30px;
}
.boutton-recherche {
  flex: 1;
  max-width: 400px;
  box-shadow: 0 -5px 13px 0 #ae0b2069,
    inset 0 1px 1px 0 rgba(237, 237, 237, 0.09);
  background-color: #e2ad2b;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  height: 7vh;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  border-color: #e2ad2b;
}
@media screen and (max-width: 750px) {
  .titreAgences {
    font-size: 20px;
  }

  .champ-recherche {
    font-size: 18px;
  }
  .boutton-recherche {
    display: none;
  }
  .icon_recherche {
    display: block !important;
  }
}
@media screen and (max-width: 400px) {
  .titreAgences {
    font-size: 18px;
  }
  .information-agences {
    font-size: 12px;
  }
  .lieux {
    font-size: 12px;
    font-weight: bold;
  }
}
@media screen and (max-width: 1300px) {
  .boutton-recherche {
    font-size: 8px;
  }
}
@media screen and (max-width: 1000px) {
  #nosAgences {
    margin-top: 5vh;
  }
}
