.footer {
  width: 100%;
  margin-top: 20vh;
  padding: 6rem 0;
  background-color: rgba(242, 242, 242, 0.6);
  /* height: 60vh; */
}

.footer-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

/* CSS colum 1  */

.footer-col-1 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.info-footer p {
  font-family: "lato", sans-serif;
  color: #090909;
  margin-bottom: 1rem;
  margin-top: 0.9rem;
  line-height: 1.5rem;
}

.infos-data {
  display: flex;
  margin-bottom: 0.9rem;
}

.infos-data span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-faqs {
  display: flex;
  margin-bottom: 0.9rem;
}

.infos-faqs span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

/* CSS colum 2  */

.footer-col-2 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.Info-contact h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  color: #000c21;
  margin-bottom: 2rem;
}

.footer-col-2 .adresse-footer {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .adresse-footer span {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-col-2 .horloge {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .horloge span {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-col-2 .email-footer {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .email-footer span {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-col-2 .phone-footer {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .phone-footer span {
  color: #090909;
  font-family: "lato", sans-serif;
}

/* CSS colum 3  */

.footer-col-3 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.Info-info h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  color: #000c21;
  margin-bottom: 2rem;
}

.infos-cgl {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-cgl span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-mention {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-mention span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-générales {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-générales span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-Aide {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-Aide span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-2 {
  max-width: 100%;
  background-color: #e2ad2b;
  height: 60px;
  padding: 1rem 0;
}

.footer-2-container {
  max-width: 1240px;
  margin: auto;
  display: flex;
  /* grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px; */
  justify-content: space-between;
}

.footer-2-col-1 span {
  color: #fff;
  font-family: "lato", sans-serif;
}

.footer-2-col-1 {
  margin-left: 50px;
}

/* Media query */

@media (max-width: 650px) {
}

@media screen and (max-width: 650px) {
  .footer {
    width: 100%;
    height: auto;
    margin-top: 25vh;
  }
  .footer-container {
    grid-template-columns: 1fr;
    margin-left: 20px;
  }
}

@media (max-width: 950px) {
  .footer-container {
    margin-left: 20px;
  }
}


@media screen and (max-width: 400px) {
	.Info-info h3, .Info-contact h3 {
		font-size: 18px;
	}
	
	.footer-2-col-1 {
		margin-left: 20px;
	}
}