.container_page {
  display:flex;

}

@media (max-width: 650px) {
  .container_page {
    top: 202px;
    flex-direction: column;
    position: relative;
  }
}

@media  (max-width: 960px) {
 
}
