.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 100%;
  position: fixed;
  height: 105px;
  top: 0px;
  z-index: 10;
  background-color: rgba(226, 173, 43, 0.8);
}

.header-bg {
  transition: 0.5px;
  background-color: rgba(226, 173, 43, 0.8);
  width: 100%;
}

.navbar-menu {
  display: flex;
  top: 15px;
  position: relative;
}

.navbar-menu li {
  padding: 0 1rem;
}

.navbar-menu li a {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
}

.logo {
  position: relative;
  top: 0px;
  max-width: 140px;
}

.navbar-icon {
  color: white;
  display: flex;
  align-items: center;
}

.icon-search {
  margin-right: 30px;
  cursor: pointer;
  font-size: 20px;
}

.icon-user {
  cursor: pointer;
  font-size: 20px;
}

.hello {
  width: 100%;
}

.navbar-img {
  position: absolute;
  width: 100%;
}

.info-header {
  color: white;
  text-align: center;
  position: relative;
  top: 70px;
}

.info-header h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.info {
  display: block;
  position: relative;
  align-items: center;
}

.hamburger {
  display: none;
}
.seConnecter {
  display: none !important;
}
/* Screen only css */

@media screen and (max-width: 1040px) {
  .seConnecter {
    display: block !important;
  }
  .navbar-menu {
    flex-direction: column;
    top: 0px;
    left: -100%;
    padding-top: 15vh;
    align-items: center;
    width: 100%;
    height: 100vh;
    /* background: rgba(0,0, 0, 0.9); */
    background-image: linear-gradient(to bottom, #222535, #090909);
    position: absolute;
    z-index: -3;
    transition: 0.3s;
  }

  .navbar-menu.active {
    left: 0px;
  }

  .navbar-menu li {
    padding: 0.7rem 0;
  }

  .navbar-menu li a {
    font-size: 16px;
    color: white;
  }

  .hamburger {
    display: initial;
    margin-left: 148px;
  }

  .icon-search {
    display: none;
  }
}
