@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.bg-img {
  width: 100%;
  height: 100vh;
  position: relative;
}

.image-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.background-img {
  width: 100%;
  height: 100vh;
}

.background-img .content {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  width: 100%;
}

.background-img .content h1 {
  font-size: 35px;
  font-weight: 800;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0.6rem 0 2.8rem;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
}
@font-face {
  font-family: "Neutro";
  src: url("../assets/polices/Neutro-ExtraBold.otf");
}

@media screen and (max-width: 640px) {
  .bg-img {
    width: 100%;
    height: 50vh;
    position: relative;
  }

  .background-img .content h1 {
    font-size: 1.3rem;
    margin-top: 20vh;
  }

  .background-img .content {
    position: absolute;
    top: 26%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
  }

  .btnReservation {
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 800;
    border: 1px solid #e2ad2b;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}
@media screen and (min-width: 641px) and (max-width: 992px) {
  .indicators-carroussel {
    top: 80vh !important;
  }
}
.btnReservation {
  padding: 15px 5px;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: auto;
}
.btnRes {
  color: #fff !important;
}
