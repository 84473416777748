.image_voiture {
  width: 100%;
  max-width: 300px;
}
.affiche2 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5vh;
  padding: 0 0 0 10px;
}
.rangement span:nth-child(1) {
  color: #8a8886;
}
.rangement {
  margin-top: 2vh;
  display: flex;
  gap: 10px;
}
