#details_voitures {
  width: 80%;
  margin: auto;
}

#section-image {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  flex-wrap: wrap;
}
.entete-details {
  background-color: #fff;
  border: 1px solid rgba(204, 204, 204, 0.235);
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  flex-wrap: wrap;
}
.details-image {
  height: 50vh;
  width: 100%;
}
.entete-details > h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
}
.rating-entete {
  padding: 1vh 0;
}
.details-entete {
  display: flex;
  gap: 11px;
  flex-wrap: wrap;
}
.details-entete div:nth-child(1) {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.details-entete div:nth-child(2) {
  background-color: #e2ad2b;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  flex-wrap: wrap;
}
.details-entete div span:first-child {
  color: #e2ad2b;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
}
#bouttons-reserves {
  display: flex;
  gap: 25px;
  position: relative;
  bottom: 5vh;
  justify-content: center;
  flex-wrap: wrap;
}
.choisir-voiture {
  width: 40%;
  height: 8vh;
  background-color: #e2ad2b;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  flex-wrap: wrap;
}
.partie-details-voitures {
  display: flex;
  flex-direction: column;
  padding: 0 20px 30px 20px;
  line-height: 5vh;
  flex-wrap: wrap;
}
#sous-groupe {
  display: flex;
  flex: 1;
}
#section-groupe-details-voiture {
  display: flex;

  flex-wrap: wrap;
}
.details-des-voiture span:nth-child(1) {
  color: #6e7684;
  font-size: 16px;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
}
.details-des-voiture span:nth-child(2),
.rating-partie {
  font-family: "Work Sans", sans-serif;
}
.rating-partie {
  margin-top: 5vh;
}
.infos-circle {
  color: #e2ad2b;
}
.details-location {
  font-family: "Work Sans", sans-serif;
  padding: 35px 20px;
}
.details-location h5 {
  font-weight: bold;
}
.details-location-droite,
.details-location-gauche {
  line-height: 6vh;
}
.details-location-partie {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.reviews-partie {
  padding: 35px 20px;
}
.reviews-partie h2 {
  font-family: "Montserrat", sans-serif;
}
.reviews-ensemble {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.titre-reviews {
  font-family: "Montserrat", cursive;
}
.reviews-texte {
  font-family: "Work Sans", sans-serif;
  margin-top: 2vh;
}
#groupe-details {
  flex: 2;
  line-height: 6vh;
}
.reviews-images {
  display: flex;
  gap: 25px;
  margin-top: 3vh;
  flex-wrap: wrap;
}
.reviews-images div {
  width: 250px;
  height: 20vh;
  background-color: #ccc;
}
.reviews {
  margin-bottom: 2vh;
  margin-top: 2vh;
}
.voir-commentaires {
  display: flex;
  color: #e2ad2b;
  gap: 5px;
  padding: 5px 20px;
  font-family: "Montserrat", sans-serif;
  justify-content: flex-end;
  font-size: 10px;
}
@media screen and (max-width: 1200px) {
  #section-groupe-details-voiture {
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  .details-image {
    height: 30vh !important;
  }
  .details-location-partie {
    gap: 0px;
  }
  #sous-groupe {
    gap: 0px;
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  #details_voitures {
    width: 100%;
  }
  .voir-commentaires {
    justify-content: flex-start !important;
    margin-bottom: 2vh;
  }

  .reviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .reviews-texte {
    margin: 2vh 0;
    width: 100%;
  }
  .reviews-images {
    justify-content: center;
  }
  .reviews-partie h2 {
    font-size: 16px;
  }

  .entete-details h3 {
    font-size: 12px;
  }
  .rating-entete {
    font-size: 13px;
  }
  .choisir-voiture {
    font-size: 13px;
  }
}
@media screen and (min-width: 992px) {
  .reviews-texte {
    width: 60%;
  }
}
