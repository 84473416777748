.section-avis {
  position: relative;
  top: 15vh;
  margin-bottom: 10vh;
}
.titre-section-avis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}
.titre-avis {
  font-weight: 500;
  font-size: 33px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
.tiret-droit,
.tiret-gauche {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.avis {
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #090909;
  display: block;
  max-width: 700px;
  font-size: 14px;
  margin: auto;
  line-height: 3vh;
  margin-top: 3vh;
  font-weight: 600;
}
@media screen and (max-width: 640px) {
  .titre-avis {
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) {
  .titre-avis {
    font-size: 18px;
  }
}
