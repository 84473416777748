.ContainerEditProfil {
  max-width: 80%;
  margin: auto;
  position: relative;
  top: 30px;
}

.section-edit {
  display: flex;
  align-items: center;
}

.details-edit {
  position: relative;
  width: 345px;
  height: 339px;
  padding: 32px 24px 63px 25px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: #f2f2f2;
}

.details-edit h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
}
.profile-user-edit {
  display: flex;
  width: 100%;
  height: 70px;
  position: relative;
  top: 20px;
}

.profile-user-edit .icon-profile-compte-edit {
  color: #090909;
  position: relative;
  font-size: 18px;
  margin: 0 8px -2px 4px;
}

.profile-user-edit span {
  font-family: "WorkSans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}

.details-edit .d span {
  color: #e2ad2b;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
}

.Path-edit {
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 32px 37px 19px;
  background-color: #e2ad2b;
}

/* CCS FORMULAIRE EDIT */

.containerEdit-profil {
  width: 100%;
  position: relative;
  left: 40px;
}

.formEditGroup {
  position: relative;
  top: 10px;
}

.formInputEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoEdit-profil h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.infoEdit-profil {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.formInputEdit .inputFieldEdit {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputFieldEdit input {
  width: 95%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.formEditGroup .adress input {
  width: 98%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.inputFieldEdit input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

.formEditGroup .button {
  margin-top: 20px;
}

.formEditGroup .button input {
  border: none;
  background-color: #e2ad2b;
  color: white;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
  height: 50px;
  width: 98%;
}

.retourPageCompte {
  text-align: center;
  margin-top: 30px;
}

.retourPageCompte a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.retourPageCompte span {
  color: #090909;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.retourPageCompte a:hover {
  text-decoration: underline;
}

/* CSS MEDIA MOBILE */

@media (max-width: 650px) {
  .ContainerEditProfil {
    position: relative;
    width: 100%;
    height: 100%;
    top: 130px;
  }

  .section-edit {
    display: block;
    flex-direction: column;
    width: 100%;
  }

  .details-edit {
    position: relative;
    top: 0px;
    width: 115%;
  }

  .details-edit h2 {
    font-size: 14px;
  }

  /* CSS FORMULAIRE */

  .containerEdit-profil {
    width: 115%;
    position: relative;
    left: 0px;
  }

  .formInputEdit {
    display: block;
  }

  .inputFieldEdit input {
    width: 100%;
    height: 100%;
  }

  .formEditGroup .adress input {
    width: 100%;
    height: 100%;
  }

  .infoEdit-profil {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }

  .infoEdit-profil h2 {
    font-size: 14px;
  }

  .details-edit span {
    font-size: 14px;
  }

  .details-edit .d span {
    font-size: 14px;
  }

  .formEditGroup .button input {
    font-size: 14px;
  }
}

/* CSS MEDIA TABLETTE */

@media (max-width: 960px) {
  .ContainerEditProfil {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 15px;
  }

  .infoEdit-profil h2 {
    font-size: 14px;
  }
}
