.promotion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  margin: 0 auto;
}
.titre-promotion {
  color: #000c21;
  font-weight: 500;
  font-size: 38px;
  font-family: "Montserrat", cursive !important;
}
.tiret-droit-promotion,
.tiret-gauche-promotion {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.promotion-texte {
  color: #1f2b3e;
  margin: auto;
  font-family: "Lato", sans-serif !important;
  font-size: 15px;
  max-width: 500px;
  text-align: center;
  display: block;
  margin-bottom: 3vh;
}
@media screen and (max-width: 640px) {
  .titre-promotion {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  .titre-promotion {
    font-size: 18px;
  }
  .promotion {
    margin-top: 0vh;
  }
  .promotion-texte {
    margin-top: 3vh;
  }
}
