#section-collection {
  display: flex;
  justify-content: center;
  width: 98%;
  gap: 30px;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
}
#collection-pagination {
  display: flex;
  flex-direction: column;
}
#collections {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  height: auto;
  flex: 3;
  width: 100%;
}
#filtres {
  height: auto;
  width: 300px;
}

/*Responsivité*/

@media screen and (max-width: 640px) {
  #section-collection2 {
    margin-top: 30vh;
  }
}
