.container_f {
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  left: 10%;
  width: 32%;
}

.category,
.centre {
  background: rgba(225, 223, 223, 0.63);
  padding: 5vh;
  line-height: 2;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}
.item {
  padding: 10px;
}
.faq_Link {
  flex-grow: 0;
  margin: 7px 0 29px 14px;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  font-weight: 600;
}

.faq_Link:hover{
  color: orange;
}

.centre > input::placeholder {
  flex-grow: 0;
  margin: 7px 0 29px 0px;
  font-family: "Lato";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}
.category h3 {
  width: 269px;
  height: 50px;
  flex-grow: 0;
  margin: 0 27px 13px 0;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
.centre h3 {
  width: 268px;
  height: 46px;
  margin: 0 0 18px;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
.inp-search > input::placeholder {
  width: 100%;
  height: 7vh;
  padding-left: 25px;
  padding: 12px 7px 0.875em 29px;

  background: rgb(255, 255, 255);
  border: 1px rgb(201, 193, 193) solid;
  border-radius: 4px;
  color: gray;
  position: relative;
}

.inp-search {
  width: 100%;
  height: 7vh;
  padding-left: 25px;
  padding: 12px 7px 0.875em 29px;

  background: rgb(255, 255, 255);
  border: 1px rgb(201, 193, 193) solid;
  border-radius: 4px;
  color: gray;
  position: relative;
}
.Fa_search {
  color: rgb(162, 162, 162);
  position: relative;
  top: 33px;
  left: 7px;
  z-index: 1;
}
@media screen and (max-width: 720px) {
  .category h3 {
    font-size: 14px;
  }
}

.item span{
  font-family: "Lato", sans-serif;
}

@media (max-width: 650px) {

  .category, .centre {
    
    padding: 10px;
    width: 100%;
    
}

  .container_f {
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    left:0px;
    width:auto;
}


 
}
