.elementtabs {
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  color: #000;
}
.activetab {
  color: #e2ad2b;
  font-weight: bold;
}
