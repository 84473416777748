.conatiner_info {
  width: 45%;
  position: relative;
  height: auto;
  flex-grow: 0;
  margin: auto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  color: #090909;
  left: 20px;
}

.head_info > h3 {
  height: 50px;
  width: 100%;
  flex-grow: 0;
  /* margin: 0 0 20px; */
  font-family: "Montserrat";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
.category_question {
  position: relative;
  left: -5%;
  margin-top: 30px;
  margin-bottom: 30px;
}

details {
  margin: -1rem 2rem;
  padding: 0 1rem;
  width: 35em;
  max-width: calc(100% - 2rem);
  position: relative;
  border: none;
  color: #263238;
  transition: background-color 0.15s;
}
details > :last-child {
  margin-bottom: -4rem;
  height: 200px;
}
details::before {
  width: 100%;
  height: auto;
  content: "";
  position: absolute;
  top: 0;
  border-radius: inherit;
  opacity: 0.15;
  pointer-events: none;
  transition: opacity 0.2s;
  z-index: -1;
}
details[open] {
  background-color: rgba(216, 199, 207, 0);
  color: #263238;
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}
details[open] > summary {
  color: orange;
  margin-bottom: 0px;
}
details[open]::before {
  opacity: 0.6;
}

summary {
  padding: 1rem 2em 1rem 0;
  display: block;
  position: relative;
  font-weight: bold;
  cursor: pointer;
  width: 130%;
  height: auto;
  flex-grow: 0;
  margin: 9px 0 2px 8.3px;
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
summary::before,
summary::after {
  width: 0.75em;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  left: -5%;
  content: "";
  background-color: currentColor;
  text-align: right;
  color: orange;
  transform: translateY(-50%);
  transition: transform 0.3s ease-in-out;
}
summary::after {
  transform: translateY(-50%) rotate(90deg);
}
[open] summary::after {
  transform: translateY(-50%) rotate(180deg);
}
summary::-webkit-details-marker {
  display: none;
}

.head_info p {
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #090909;
}

ul {
  margin: 0 0 1em;
  padding: 0 0 0 1em;
}

li:not(:last-child) {
  margin-bottom: 0.5em;
}
li {
  margin-bottom: 0.5em;
}

/* Css mobile */
@media (max-width: 650px) {
  .head_info > h3 {
    font-size: 16px;
    width: auto;
  }

  .head_info {
    width: 111%;
  }

  .conatiner_info {
    top: 20px;
    width: 86%;
    position: relative;
    height: auto;
    flex-grow: 0;
    margin: 0px;
    left: 10px;
  }

  .container_f .search {
    display: none;
  }
  .category_question {
    position: relative;
    left: -12%;
    margin-top: 20px;
    width: 102%;
    margin-bottom: 20px;
  }

  .category_question summary {
    padding: 1rem 2em 1rem 0;
    display: block;
    position: relative;
    width: 128%;
    margin: 0px;
    font-size: 14px;
  }

  details[open] {
    font-size: 12px;
    font-family: "WorkSans", sans-serif;
  }
}
