.containerDetails {
  display: flex;
  justify-content: center;
  gap: 100px;
  width: 98%;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
}
.filtres-details {
  flex: 1;
}
.details-voiture {
  flex: 3;
}
@media screen and (max-width: 992px) {
  .containerDetails {
    flex-direction: column;
  }
  .filtres-details {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .containerDetails {
    margin-top: 30vh;
  }
}
