.affiche2-mobile {
  display: none;
}
@media screen and (max-width: 640px) {
  .affiche2-mobile {
    display: block;
    margin-top: 30vh;
  }
}
@media screen and (max-width: 700px) {
  .affiche2-mobile {
    display: block;
  }
  .table-affichage {
    display: none;
  }
}
