@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
.service {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  flex-direction: column;
  color: #fff !important;
}
.service-description2 {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 12px;
}
.service-title {
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
  font-size: 14px;
  margin-top: 3vh;
}
