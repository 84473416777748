.hero-img {
  position: relative;
  width: 100%;
  height: 50vh;
  top: -20px;
  bottom: 50vh;
}
.hero-img::before {
  content: "";
  background-image: url(".././assets/HeaderBg.png");
  background-size: cover;
  background-position: center top;
  position: absolute;
  width: 100%;
  height: 50vh;
  top: -20px;
  left: 0;
  z-index: -1;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0%;
  position: relative;
}

.heading h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 640px) {
  .hero-img {
    width: 100%;
    height: 20vh;
    background-size: cover;
    background-position: center top;
  }

  .heading h1 {
    font-size: 1.7rem;
    display: flex;
    margin-top: 35vh;
  }
}
.btn {
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}
