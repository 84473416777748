.card {
  position: relative;
}

.car-name {
  width: 100%;
  height: 5vh;
  font-family: "Montserrat", sans-serif !important;
}
.featured {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 17vh;
  width: 100%;
}
.featured-text {
  background-color: red;
  padding: 1.5vh 3vh;
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  color: #fff;
}
.featured-text-disponible {
  background-color: #ffb926;
  padding: 1.5vh 3vh;
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  color: #fff;
}

.car-prix {
  color: #ffb926;
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px;
}
.car-rating {
  font-family: "Lato", sans-serif !important;
  font-size: 12px;
  background-color: #ffb926;
  font-weight: bold;
  border: none;
  color: #fff;
  padding: 0.5vh 20px;
  margin-top: 0.2vh;
}
.details-auto {
  font-size: 14px;
  position: relative;
  bottom: 0;
  display: flex;
}
/*
.passagers {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #ccc;
  flex: 1;
  text-align: center !important;
}*/
