.pageLogin {
  display: flex;
  max-width: 80%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 30px;
  position: relative;
  top: 50px;
}

.contenairLoginForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
}

.formLogin {
  width: 100%;
  position: relative;
  left: 15px;
}

.imageLogin img {
  width: 400px;
  left: 10px;
}

.infoLogin h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}

.infoLogin span {
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}

.formLoginGroup {
  position: relative;
  top: 10px;
}

.formLoginGroup .inputField {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputField input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 35px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.inputField input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

.iconLogin {
  position: absolute;
  top: 30%;
  font-size: 16px;
  left: 10px;
  right: 0px;
  z-index: 9;
  color: #abb3bb;
}

.iconLoginEyes {
  position: absolute;
  top: 30%;
  font-size: 16px;
  right: 10px;
  z-index: 9;
  color: #abb3bb;
}

.formLoginGroup .checkbox-text {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-content {
  display: flex;
  align-items: center;
}

.checkbox-content input {
  accent-color: #e2ad2b;
  margin: 0 8px -2px 4px;
}

.checkbox-content span {
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
}

.checkbox-text a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.checkbox-text a:hover {
  text-decoration: underline;
}

.formLoginGroup .button {
  margin-top: 20px;
}

.formLoginGroup .button input {
  border: none;
  background-color: #e2ad2b;
  color: white;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.signUpForm {
  text-align: center;
  margin-top: 20px;
}

.signUpForm a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.signUpForm span {
  color: #090909;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.signUpForm a:hover {
  text-decoration: underline;
}

/* CSS MOBILE */
@media (max-width: 650px) {
  .containerLogin {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .pageLogin {
    max-width: 125%;
    position: relative;
    padding: 15px;
    top: 80px;
    margin: -35px;
  }
  .CircularArea {
    position: relative;
    top: 250px;
    margin: -35px;
  }

  .contenairLoginForm {
    display: block;
    flex-direction: column;
  }

  .imageLogin img {
    display: none;
  }

  .formLogin {
    width: 100%;
    position: relative;
    left: 0px;
  }

  .infoLogin h2 {
    font-size: 18px;
  }
  .infoLogin span {
    font-size: 14px;
  }

  .formLoginGroup .button input {
    font-size: 14px;
  }
}

/* DEVICES AND TABLETTE */

@media (max-width: 960px) {
  .contenairLoginForm {
    display: block;
    flex-direction: column;
  }

  .imageLogin img {
    display: none;
  }
}
