.containerCompte{

    max-width: 80%;
    margin: auto;
    /* background-color: red; */
    position: relative;
    top: 30px;
}

.section-My-Comte{
  display: flex;
  align-items: center;
  
}

.details-Compte{
  position: relative;
  width: 345px;
  height: 339px;
  padding: 32px 24px 63px 25px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: #f2f2f2;
  top:-290px

}

.details-Compte h2{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
}
.profile-user{
  display: flex;
  width: 100%;
  height: 70px;
  position: relative;
  top: 20px;

  
}

.profile-user .icon-profile-compte{
  color: #090909;
  position: relative;
  font-size: 18px;
  margin: 0 8px -2px 4px ;
} 

.profile-user span{
  font-family: 'WorkSans',sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}

.details-Compte .d span{
  color: #e2ad2b;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;

}

.Path {
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 32px 37px 19px;
  background-color: #e2ad2b;
}

/* CSS partie Compte */

.information-Compte{
  position: absolute;
  left: 80px;
}

.information-Compte h2{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
}

.information-Compte{
  position: relative;
  width: 60%;

}

.info-user{
  display: flex;
  width: 100%;
  height: 50px;
  position: relative;
  top: 20px;
}

.info-user .n-compte{
  color: #6e7684;
  position: relative;
  font-size: 16px;
  margin: 0 8px -2px 4px ;
  font-family: 'WorkSans',sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
} 

.info-user h4{
  font-family: 'WorkSans',sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
  
}

.information-Compte .m span{
  color: #e2ad2b;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;

}
.Path-info{
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 0px 0px 4px;
  background-color: #e2ad2b;
}

.uderline{
  position: relative;
  margin-top: 40px;
  background-color: #d8d8d8;
}


/* CSS Profile de reservation */

.profile-Reservation{
  position: relative;
  width: 100%;

}

.profile-Reservation h2{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
  margin-top: 40px;
}


.info-reservation{
  display: flex;
  width: 100%;
  height: 50px;
  position: relative;
  top: 20px;
}

.info-reservation .n-reservation{
  color: #6e7684;
  position: relative;
  font-size: 16px;
  margin: 0 8px -2px 4px ;
  font-family: 'WorkSans',sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
} 

.info-reservation h4{
  font-family: 'WorkSans',sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
  
}

.profile-Reservation .r span{
  color: #e2ad2b;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;

}
.Path-r{
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 0px 0px 4px;
  background-color: #e2ad2b;
}

/* CSS Historique de reservation */

@media (max-width:650px){

  .containerCompte{
    position: relative;
    width: 100%;
    height: 100%;
    top: 130px;
   
    }

    .section-My-Comte{
      display: block;
      flex-direction: column;
      width: 100%;
      
    }

    .details-Compte{
      position: relative;
      top:0px;
      width: 115%;
      
    }

    .details-Compte h2{
      font-size: 14px;
    }

    .profile-user span{
      font-size: 14px;
    }

    .details-Compte .d span{
      font-size: 14px;
    }


    .information-Compte {
      position: relative;
      width: 100%;
      top: 20px;
      left: 20px;
  }

  .information-Compte h2{
    font-size: 14px;
  }

  .profile-Reservation h2 {
    font-size: 13px;
  }

  .profile-Reservation .r span, .information-Compte .m span{
    color: #e2ad2b;
    font-size: 12px;
  }

  .Path-info {
    display: none;

}

  .Path-r {
   display: none;
 
}

}


@media  (max-width:960px){

  .containerCompte{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 15px;
    }


    .information-Compte{
      position: relative;
      left:30px;
    }


}
