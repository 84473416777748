.item-avis {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3vh;
  height: 250px;
  width: 100%;
  color: #212433 !important;
  background-color: rgba(242, 242, 242, 0.6);
  color: #fff;
  margin: 8vh 15px 0 15px;
  font-size: 4em;
  padding: 0 8px;
}
button.rec-dot,
button.rec-arrow {
  background-color: #e2ad2b;
  box-shadow: 0 0 1px 3px #e2ad2b;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus,
button.rec-arrow:hover,
button.rec-arrow:active,
button.rec-arrow:focus {
  box-shadow: 0 0 1px 3px #e2ad2b !important;
}
button.rec-arrow:hover {
  background-color: #e2ad2b !important;
}
