.pageRegister {
  display: flex;
  max-width: 70%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 30px;
  position: relative;
  top: 50px;
}

.formInputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}

.formRegister {
  width: 100%;
  position: relative;
}

.infoRegister h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.infoRegister {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pointerRegister {
  width: 20px;
  height: 8px;
  flex-grow: 0;
  background-color: #e2ad2b;
  align-items: center;
}

.formRegisterGroup {
  position: relative;
  top: 10px;
}

.formRegisterGroup .inputFieldRegister {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputFieldRegister input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.inputFieldRegister input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

.formRegisterGroup .button {
  margin-top: 40px;
}

.formRegisterGroup .button input {
  border: none;
  background-color: #e2ad2b;
  color: white;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.YourPersonal {
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
  position: relative;
  top: 20px;
  left: 30px;
  color: #abb3bb;
}

.loginUpForm {
  text-align: center;
  margin-top: 30px;
}

.loginUpForm a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.loginUpForm span {
  color: #090909;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.loginUpForm a:hover {
  text-decoration: underline;
}

/* CSS MOBILE */
@media screen and (max-width: 650px) {
  .containerRegister {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .formInputGroup {
    display: block;
  }

  .pageRegister {
    max-width: 100%;
    grid-template-columns: 1fr;
    position: relative;
    padding: 15px;
    top: 140px;
  }

  .infoRegister {
    width: 100%;
    position: relative;
  }

  .infoRegister h2 {
    font-size: 19px;
  }

  .formRegisterGroup .button input {
    font-size: 14px;
  }

  .YourPersonal {
    left: 0px;
  }
}
