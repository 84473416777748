.section-pourquoi {
  width: 100%;
  height: auto;
  position: relative;
  top: 5vh;
  background-image: linear-gradient(to bottom, #222535, #090909);

  padding: 30px 30px 100px 30px;
  color: white;
}
.titre-caroussel,
.tarif-carroussel {
  font-family: "Montserrat", sans-serif;
}
.titre {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  margin: 0 auto;
}
.titrepn {
  font-weight: 500;
  font-size: 33px;
  font-family: "Montserrat", sans-serif;
}
.tiret-droit,
.tiret-gauche {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.section-services {
  display: flex;
  max-width: 1000px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-top: 8vh;
  gap: 3vh;
  color: #fff !important;
}
.section-pub {
  width: 50%;
  height: auto;
  position: relative;
  padding-bottom: 5vh;
  top: 30vh;
  bottom: -2vh;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    327deg,
    #0c0c0f 0%,
    rgba(9, 9, 9, 0.72) -47%
  );
  color: #fff;
}

@media screen and (max-width: 640px) {
  .titrepn {
    font-size: 20px;
  }
  .section-pub {
    top: 30vh;
  }
}
@media screen and (max-width: 992px) {
  .section-pub {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .titrepn {
    font-size: 18px;
  }
}
