@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

/* body{
  background-color: black;
} */

h1,
h4,
p,
a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btnReservation {
  padding: 18px 32px;
  border-radius: 10px;
  background-color: #e2ad2b;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  width: 100%;
  position: fixed;
  height: 105px;
  top: 0px;
  z-index: 10;
  background-color: rgba(226, 173, 43, 0.8);
}

.header-bg {
  transition: 0.5px;
  background-color: rgba(226, 173, 43, 0.8);
  width: 100%;
}

.navbar-menu {
  display: flex;
  top: 15px;
  position: relative;
}

.navbar-menu li {
  padding: 0 1rem;
}

.navbar-menu li a {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
}

.logo {
  position: relative;
  top: 0px;
  max-width: 140px;
}

.navbar-icon {
  color: white;
  display: flex;
  align-items: center;
}

.icon-search {
  margin-right: 30px;
  cursor: pointer;
  font-size: 20px;
}

.icon-user {
  cursor: pointer;
  font-size: 20px;
}

.hello {
  width: 100%;
}

.navbar-img {
  position: absolute;
  width: 100%;
}

.info-header {
  color: white;
  text-align: center;
  position: relative;
  top: 70px;
}

.info-header h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

.info {
  display: block;
  position: relative;
  align-items: center;
}

.hamburger {
  display: none;
}
.seConnecter {
  display: none !important;
}
/* Screen only css */

@media screen and (max-width: 1040px) {
  .seConnecter {
    display: block !important;
  }
  .navbar-menu {
    flex-direction: column;
    top: 0px;
    left: -100%;
    padding-top: 15vh;
    align-items: center;
    width: 100%;
    height: 100vh;
    /* background: rgba(0,0, 0, 0.9); */
    background-image: linear-gradient(to bottom, #222535, #090909);
    position: absolute;
    z-index: -3;
    transition: 0.3s;
  }

  .navbar-menu.active {
    left: 0px;
  }

  .navbar-menu li {
    padding: 0.7rem 0;
  }

  .navbar-menu li a {
    font-size: 16px;
    color: white;
  }

  .hamburger {
    display: initial;
    margin-left: 148px;
  }

  .icon-search {
    display: none;
  }
}

@media screen and (max-width: 1040px) {
  .connexion {
    display: none;
    background-color: red;
  }
}

.bg-img {
  width: 100%;
  height: 100vh;
  position: relative;
}

.image-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.background-img {
  width: 100%;
  height: 100vh;
}

.background-img .content {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
  width: 100%;
}

.background-img .content h1 {
  font-size: 35px;
  font-weight: 800;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
  text-transform: uppercase;
  padding: 0.6rem 0 2.8rem;
  font-family: "Montserrat";
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 50%);
}
@font-face {
  font-family: "Neutro";
  src: url(/static/media/Neutro-ExtraBold.fba864be.otf);
}

@media screen and (max-width: 640px) {
  .bg-img {
    width: 100%;
    height: 50vh;
    position: relative;
  }

  .background-img .content h1 {
    font-size: 1.3rem;
    margin-top: 20vh;
  }

  .background-img .content {
    position: absolute;
    top: 26%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5vh;
  }

  .btnReservation {
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 800;
    border: 1px solid #e2ad2b;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}
@media screen and (min-width: 641px) and (max-width: 992px) {
  .indicators-carroussel {
    top: 80vh !important;
  }
}
.btnReservation {
  padding: 15px 5px;
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin: auto;
}
.btnRes {
  color: #fff !important;
}

.footer {
  width: 100%;
  margin-top: 20vh;
  padding: 6rem 0;
  background-color: rgba(242, 242, 242, 0.6);
  /* height: 60vh; */
}

.footer-container {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

/* CSS colum 1  */

.footer-col-1 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.info-footer p {
  font-family: "lato", sans-serif;
  color: #090909;
  margin-bottom: 1rem;
  margin-top: 0.9rem;
  line-height: 1.5rem;
}

.infos-data {
  display: flex;
  margin-bottom: 0.9rem;
}

.infos-data span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-faqs {
  display: flex;
  margin-bottom: 0.9rem;
}

.infos-faqs span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

/* CSS colum 2  */

.footer-col-2 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.Info-contact h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  color: #000c21;
  margin-bottom: 2rem;
}

.footer-col-2 .adresse-footer {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .adresse-footer span {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-col-2 .horloge {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .horloge span {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-col-2 .email-footer {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .email-footer span {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-col-2 .phone-footer {
  display: flex;
  margin-bottom: 1.5rem;
}

.footer-col-2 .phone-footer span {
  color: #090909;
  font-family: "lato", sans-serif;
}

/* CSS colum 3  */

.footer-col-3 {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.Info-info h3 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
  line-height: 1;
  color: #000c21;
  margin-bottom: 2rem;
}

.infos-cgl {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-cgl span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-mention {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-mention span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-générales {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-générales span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.infos-Aide {
  display: flex;
  margin-bottom: 1.5rem;
}

.infos-Aide span a {
  color: #090909;
  font-family: "lato", sans-serif;
}

.footer-2 {
  max-width: 100%;
  background-color: #e2ad2b;
  height: 60px;
  padding: 1rem 0;
}

.footer-2-container {
  max-width: 1240px;
  margin: auto;
  display: flex;
  /* grid-template-columns: repeat(2, 1fr);
    grid-gap: 100px; */
  justify-content: space-between;
}

.footer-2-col-1 span {
  color: #fff;
  font-family: "lato", sans-serif;
}

.footer-2-col-1 {
  margin-left: 50px;
}

/* Media query */

@media (max-width: 650px) {
}

@media screen and (max-width: 650px) {
  .footer {
    width: 100%;
    height: auto;
    margin-top: 25vh;
  }
  .footer-container {
    grid-template-columns: 1fr;
    margin-left: 20px;
  }
}

@media (max-width: 950px) {
  .footer-container {
    margin-left: 20px;
  }
}


@media screen and (max-width: 400px) {
	.Info-info h3, .Info-contact h3 {
		font-size: 18px;
	}
	
	.footer-2-col-1 {
		margin-left: 20px;
	}
}
#reservationCard {
  width: 60%;
  height: auto;
  background-image: linear-gradient(to bottom, #222535, #090909);
  margin: 0 auto;
  position: relative;
  top: -10vh;
  font-family: "Work Sans", sans-serif;
  padding: 5px 30px 20px 30px;
}
.inputs-fields {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1vh;
}

.label-lieu,
.label-date,
.label-heure {
  display: block;
  color: #f2f2f2;
  font-size: 14px;
  font-weight: normal;
}
.date {
  flex: 1.5 1;
}
.heure {
  flex: 1 1;
}
.groupe-radios {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-bottom: 3vh;
}
.btn-reserver {
  width: 300px !important;
  height: 8vh;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #e2ad2b;
  text-transform: uppercase;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectVoiture {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 60px;
  gap: 60px;
  width: 100%;
  flex-wrap: wrap;
}
@media screen and (max-width: 770px) {
  #reservationCard {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .boutton-reserver {
    margin-top: 3vh;
  }
  #service-chauffeur {
    margin-bottom: 4vh;
  }
  .heure,
  .date,
  #service-chauffeur {
    margin-top: 3vh;
  }
  .inputs-fields {
    display: block !important;
  }
  .lieu {
    display: block !important;
  }
  .selectVoiture {
    display: block;
  }
  .groupe-radios {
    display: block;
  }
}

.card {
  position: relative;
}

.car-name {
  width: 100%;
  height: 5vh;
  font-family: "Montserrat", sans-serif !important;
}
.featured {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 17vh;
  width: 100%;
}
.featured-text {
  background-color: red;
  padding: 1.5vh 3vh;
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  color: #fff;
}
.featured-text-disponible {
  background-color: #ffb926;
  padding: 1.5vh 3vh;
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
  color: #fff;
}

.car-prix {
  color: #ffb926;
  font-family: "Montserrat", sans-serif !important;
  font-size: 12px;
}
.car-rating {
  font-family: "Lato", sans-serif !important;
  font-size: 12px;
  background-color: #ffb926;
  font-weight: bold;
  border: none;
  color: #fff;
  padding: 0.5vh 20px;
  margin-top: 0.2vh;
}
.details-auto {
  font-size: 14px;
  position: relative;
  bottom: 0;
  display: flex;
}
/*
.passagers {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #ccc;
  flex: 1;
  text-align: center !important;
}*/


.elementtabs {
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  color: #000;
}
.activetab {
  color: #e2ad2b;
  font-weight: bold;
}

.promotion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  margin: 0 auto;
}
.titre-promotion {
  color: #000c21;
  font-weight: 500;
  font-size: 38px;
  font-family: "Montserrat", cursive !important;
}
.tiret-droit-promotion,
.tiret-gauche-promotion {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.promotion-texte {
  color: #1f2b3e;
  margin: auto;
  font-family: "Lato", sans-serif !important;
  font-size: 15px;
  max-width: 500px;
  text-align: center;
  display: block;
  margin-bottom: 3vh;
}
@media screen and (max-width: 640px) {
  .titre-promotion {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  .titre-promotion {
    font-size: 18px;
  }
  .promotion {
    margin-top: 0vh;
  }
  .promotion-texte {
    margin-top: 3vh;
  }
}

.service {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  flex-direction: column;
  color: #fff !important;
}
.service-description2 {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 12px;
}
.service-title {
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
  font-size: 14px;
  margin-top: 3vh;
}

.section-pourquoi {
  width: 100%;
  height: auto;
  position: relative;
  top: 5vh;
  background-image: linear-gradient(to bottom, #222535, #090909);

  padding: 30px 30px 100px 30px;
  color: white;
}
.titre-caroussel,
.tarif-carroussel {
  font-family: "Montserrat", sans-serif;
}
.titre {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 650px;
  margin: 0 auto;
}
.titrepn {
  font-weight: 500;
  font-size: 33px;
  font-family: "Montserrat", sans-serif;
}
.tiret-droit,
.tiret-gauche {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.section-services {
  display: flex;
  max-width: 1000px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-top: 8vh;
  grid-gap: 3vh;
  gap: 3vh;
  color: #fff !important;
}
.section-pub {
  width: 50%;
  height: auto;
  position: relative;
  padding-bottom: 5vh;
  top: 30vh;
  bottom: -2vh;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    327deg,
    #0c0c0f 0%,
    rgba(9, 9, 9, 0.72) -47%
  );
  color: #fff;
}

@media screen and (max-width: 640px) {
  .titrepn {
    font-size: 20px;
  }
  .section-pub {
    top: 30vh;
  }
}
@media screen and (max-width: 992px) {
  .section-pub {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .titrepn {
    font-size: 18px;
  }
}

#nosAgences {
  width: 100%;
  min-height: 90vh;
  position: relative;
  background-image: linear-gradient(to top, #090909 114%, #2f2f2f -3%);
  padding: 10vh 0;
  color: white;
  margin-top: 10vh;
  top: 5vh;
}
.details-agences {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}
.titreAgences {
  font-weight: 500;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}
.tiret-droit,
.tiret-gauche {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.emplacements-agence {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  margin-top: 2vh;
  font-family: "Work Sans", sans-serif;
}
.information-agences {
  font-size: 18px;
  font-weight: 100;
  line-height: 3vh;
}
.lieux {
  font-size: 18px;
  font-weight: bold;
}

.recherche-agence {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  grid-gap: 2vh;
  gap: 2vh;
  margin-top: 4vh;
}
.champ-recherche {
  flex: 3 1;
  background-color: #fff;
  max-width: 600px;
  border-radius: 1px;
  height: 7vh;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 0 30px;
}
.boutton-recherche {
  flex: 1 1;
  max-width: 400px;
  box-shadow: 0 -5px 13px 0 #ae0b2069,
    inset 0 1px 1px 0 rgba(237, 237, 237, 0.09);
  background-color: #e2ad2b;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  height: 7vh;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  border-color: #e2ad2b;
}
@media screen and (max-width: 750px) {
  .titreAgences {
    font-size: 20px;
  }

  .champ-recherche {
    font-size: 18px;
  }
  .boutton-recherche {
    display: none;
  }
  .icon_recherche {
    display: block !important;
  }
}
@media screen and (max-width: 400px) {
  .titreAgences {
    font-size: 18px;
  }
  .information-agences {
    font-size: 12px;
  }
  .lieux {
    font-size: 12px;
    font-weight: bold;
  }
}
@media screen and (max-width: 1300px) {
  .boutton-recherche {
    font-size: 8px;
  }
}
@media screen and (max-width: 1000px) {
  #nosAgences {
    margin-top: 5vh;
  }
}

.item-avis {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
  height: 250px;
  width: 100%;
  color: #212433 !important;
  background-color: rgba(242, 242, 242, 0.6);
  color: #fff;
  margin: 8vh 15px 0 15px;
  font-size: 4em;
  padding: 0 8px;
}
button.rec-dot,
button.rec-arrow {
  background-color: #e2ad2b;
  box-shadow: 0 0 1px 3px #e2ad2b;
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus,
button.rec-arrow:hover,
button.rec-arrow:active,
button.rec-arrow:focus {
  box-shadow: 0 0 1px 3px #e2ad2b !important;
}
button.rec-arrow:hover {
  background-color: #e2ad2b !important;
}

.section-avis {
  position: relative;
  top: 15vh;
  margin-bottom: 10vh;
}
.titre-section-avis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
}
.titre-avis {
  font-weight: 500;
  font-size: 33px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
.tiret-droit,
.tiret-gauche {
  width: 20px;
  height: 1vh;
  background-color: #e2ad2b;
}
.avis {
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #090909;
  display: block;
  max-width: 700px;
  font-size: 14px;
  margin: auto;
  line-height: 3vh;
  margin-top: 3vh;
  font-weight: 600;
}
@media screen and (max-width: 640px) {
  .titre-avis {
    font-size: 20px;
  }
}

@media screen and (max-width: 400px) {
  .titre-avis {
    font-size: 18px;
  }
}

.hero-img {
  position: relative;
  width: 100%;
  height: 50vh;
  top: -20px;
  bottom: 50vh;
}
.hero-img::before {
  content: "";
  background-image: url(/static/media/HeaderBg.52e470fc.png);
  background-size: cover;
  background-position: center top;
  position: absolute;
  width: 100%;
  height: 50vh;
  top: -20px;
  left: 0;
  z-index: -1;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0%;
  position: relative;
}

.heading h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 640px) {
  .hero-img {
    width: 100%;
    height: 20vh;
    background-size: cover;
    background-position: center top;
  }

  .heading h1 {
    font-size: 1.7rem;
    display: flex;
    margin-top: 35vh;
  }
}
.btn {
  padding: 2px 5px;
  font-size: 12px;
  font-weight: 800;
  border: 1px solid #e2ad2b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.collection-cars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  grid-gap: 8px;
  gap: 8px;
  height: auto;
  margin-bottom: 5vh;
}

#section-filtre-header {
  width: 100%;
  height: 10vh;
  color: #fff;
  background-color: #d3a229;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

#section-filtres {
  width: 100%;
  height: auto;
  background-color: rgba(242, 242, 242, 0.7);
  font-family: "Work Sans", sans-serif;
  padding: 25px !important;
}

.prix-label {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  display: block;
  margin-bottom: 1vh;
}
.options-additionnelles {
  margin-top: 3vh;
  margin-bottom: 2vh;
}
.filtre-lieu,
.filtre-date,
.choix-nombre,
.options {
  color: #090909;
}
.date {
  margin-bottom: 3vh;
  margin-top: 2vh;
}
.lieu-filtre {
  margin-top: 2vh;
}

.partie-checkbox {
  display: flex;
  justify-content: space-between;
}
#section-filtre-footer {
  width: 100%;
  height: 10vh;
  color: #fff;
  background-color: #d3a229;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: 3vh;
}

#search-bar {
  width: 100%;
  height: 10vh;
  background-color: rgba(0, 0, 0, 0.058);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #979797;
  font-family: "Lato", sans-serif;
}
.select-voiture {
  width: 25%;
  padding-bottom: 2vh;
}
.offre {
  font-weight: 500;
  font-size: 16px;
}
@media screen and (max-width: 640px) {
  .select-voiture {
    width: 60%;
    padding-bottom: 2vh;
  }
  .offre {
    font-size: 12px;
  }
  #search-bar {
    padding: 25px 5px;
  }
}

#section-collection {
  display: flex;
  justify-content: center;
  width: 98%;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
}
#collection-pagination {
  display: flex;
  flex-direction: column;
}
#collections {
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
  height: auto;
  flex: 3 1;
  width: 100%;
}
#filtres {
  height: auto;
  width: 300px;
}

/*Responsivité*/

@media screen and (max-width: 992px) {
  #section-collection {
    flex-direction: column;
  }
  #filtres {
    width: 95% !important;
    margin: auto;
  }
  #collections {
    width: 90% !important;
    margin: auto;
  }
}

@media screen and (max-width: 640px) {
  .spanvide,
  .renitialise {
    margin-top: 30vh !important;
  }
}

.container_page {
  display:flex;

}

@media (max-width: 650px) {
  .container_page {
    top: 202px;
    flex-direction: column;
    position: relative;
  }
}

@media  (max-width: 960px) {
 
}

.container_f {
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  left: 10%;
  width: 32%;
}

.category,
.centre {
  background: rgba(225, 223, 223, 0.63);
  padding: 5vh;
  line-height: 2;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}
.item {
  padding: 10px;
}
.faq_Link {
  flex-grow: 0;
  margin: 7px 0 29px 14px;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  font-weight: 600;
}

.faq_Link:hover{
  color: orange;
}

.centre > input::-webkit-input-placeholder {
  flex-grow: 0;
  margin: 7px 0 29px 0px;
  font-family: "Lato";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}

.centre > input::placeholder {
  flex-grow: 0;
  margin: 7px 0 29px 0px;
  font-family: "Lato";
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}
.category h3 {
  width: 269px;
  height: 50px;
  flex-grow: 0;
  margin: 0 27px 13px 0;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
.centre h3 {
  width: 268px;
  height: 46px;
  margin: 0 0 18px;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
.inp-search > input::-webkit-input-placeholder {
  width: 100%;
  height: 7vh;
  padding-left: 25px;
  padding: 12px 7px 0.875em 29px;

  background: rgb(255, 255, 255);
  border: 1px rgb(201, 193, 193) solid;
  border-radius: 4px;
  color: gray;
  position: relative;
}
.inp-search > input::placeholder {
  width: 100%;
  height: 7vh;
  padding-left: 25px;
  padding: 12px 7px 0.875em 29px;

  background: rgb(255, 255, 255);
  border: 1px rgb(201, 193, 193) solid;
  border-radius: 4px;
  color: gray;
  position: relative;
}

.inp-search {
  width: 100%;
  height: 7vh;
  padding-left: 25px;
  padding: 12px 7px 0.875em 29px;

  background: rgb(255, 255, 255);
  border: 1px rgb(201, 193, 193) solid;
  border-radius: 4px;
  color: gray;
  position: relative;
}
.Fa_search {
  color: rgb(162, 162, 162);
  position: relative;
  top: 33px;
  left: 7px;
  z-index: 1;
}
@media screen and (max-width: 720px) {
  .category h3 {
    font-size: 14px;
  }
}

.item span{
  font-family: "Lato", sans-serif;
}

@media (max-width: 650px) {

  .category, .centre {
    
    padding: 10px;
    width: 100%;
    
}

  .container_f {
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    left:0px;
    width:auto;
}


 
}

.conatiner_info {
  width: 45%;
  position: relative;
  height: auto;
  flex-grow: 0;
  margin: auto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  color: #090909;
  left: 20px;
}

.head_info > h3 {
  height: 50px;
  width: 100%;
  flex-grow: 0;
  /* margin: 0 0 20px; */
  font-family: "Montserrat";
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
.category_question {
  position: relative;
  left: -5%;
  margin-top: 30px;
  margin-bottom: 30px;
}

details {
  margin: -1rem 2rem;
  padding: 0 1rem;
  width: 35em;
  max-width: calc(100% - 2rem);
  position: relative;
  border: none;
  color: #263238;
  transition: background-color 0.15s;
}
details > :last-child {
  margin-bottom: -4rem;
  height: 200px;
}
details::before {
  width: 100%;
  height: auto;
  content: "";
  position: absolute;
  top: 0;
  border-radius: inherit;
  opacity: 0.15;
  pointer-events: none;
  transition: opacity 0.2s;
  z-index: -1;
}
details[open] {
  background-color: rgba(216, 199, 207, 0);
  color: #263238;
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}
details[open] > summary {
  color: orange;
  margin-bottom: 0px;
}
details[open]::before {
  opacity: 0.6;
}

summary {
  padding: 1rem 2em 1rem 0;
  display: block;
  position: relative;
  font-weight: bold;
  cursor: pointer;
  width: 130%;
  height: auto;
  flex-grow: 0;
  margin: 9px 0 2px 8.3px;
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}
summary::before,
summary::after {
  width: 0.75em;
  height: 1px;
  position: absolute;
  top: 50%;
  right: 0;
  left: -5%;
  content: "";
  background-color: currentColor;
  text-align: right;
  color: orange;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
summary::after {
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
}
[open] summary::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
summary::-webkit-details-marker {
  display: none;
}

.head_info p {
  font-family: "WorkSans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #090909;
}

ul {
  margin: 0 0 1em;
  padding: 0 0 0 1em;
}

li:not(:last-child) {
  margin-bottom: 0.5em;
}
li {
  margin-bottom: 0.5em;
}

/* Css mobile */
@media (max-width: 650px) {
  .head_info > h3 {
    font-size: 16px;
    width: auto;
  }

  .head_info {
    width: 111%;
  }

  .conatiner_info {
    top: 20px;
    width: 86%;
    position: relative;
    height: auto;
    flex-grow: 0;
    margin: 0px;
    left: 10px;
  }

  .container_f .search {
    display: none;
  }
  .category_question {
    position: relative;
    left: -12%;
    margin-top: 20px;
    width: 102%;
    margin-bottom: 20px;
  }

  .category_question summary {
    padding: 1rem 2em 1rem 0;
    display: block;
    position: relative;
    width: 128%;
    margin: 0px;
    font-size: 14px;
  }

  details[open] {
    font-size: 12px;
    font-family: "WorkSans", sans-serif;
  }
}

.contactFormulaire {
  max-width: 80%;
  margin: auto;
  padding: 30px;
  position: relative;
  top: -20px;
}

.formContactGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}

.title-contact {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.GroupContact .inputFieldContact {
  margin-top: 10px;
  margin-bottom: 1vh;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputFieldContact input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.inputFieldContact input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

/* .GroupContact .button{
    margin-top: 40px;
} */

.formContactSubjet input {
  width: 100%;
}

.formContactMessage textarea {
  width: 100%;
  padding: 10px;
  line-height: 150%;
  resize: none;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

#form_button {
  background: none;
  border: solid 1px #47454466;
  color: #474544;
  cursor: pointer;
  display: inline-block;
  font-family: "Montserrat";
  font-size: 0.875em;
  font-weight: bold;
  outline: none;
  padding: 20px 35px;
  border-radius: 4px;
  text-transform: uppercase;
  transition: all 0.3s;
  position: relative;
  left: 25%;
  width: 50%;
}

#form_button:hover {
  background: orange;
  color: #f2f3eb;
}

.submit-contact {
  position: relative;
  top: 110px;
}

/* CSS MOBILE */
@media screen and (max-width: 650px) {
  .formContactGroup {
    display: block;
  }
  .GroupContact .inputFieldContact {
    margin-bottom: 2.8vh !important;
  }
  .contactFormulaire {
    max-width: 100%;
    grid-template-columns: 1fr;
    padding: 15px;
    top: 30vh;
  }

  .Title-Titre {
    width: 100%;
    position: relative;
  }

  .title-contact {
    font-size: 13px;
    text-align: center;
    margin-left: 0px;
    margin-right: 50px;
  }
}

.pageLogin {
  display: flex;
  max-width: 80%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 30px;
  position: relative;
  top: 50px;
}

.contenairLoginForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-direction: column; */
}

.formLogin {
  width: 100%;
  position: relative;
  left: 15px;
}

.imageLogin img {
  width: 400px;
  left: 10px;
}

.infoLogin h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}

.infoLogin span {
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
}

.formLoginGroup {
  position: relative;
  top: 10px;
}

.formLoginGroup .inputField {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputField input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 35px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.inputField input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

.iconLogin {
  position: absolute;
  top: 30%;
  font-size: 16px;
  left: 10px;
  right: 0px;
  z-index: 9;
  color: #abb3bb;
}

.iconLoginEyes {
  position: absolute;
  top: 30%;
  font-size: 16px;
  right: 10px;
  z-index: 9;
  color: #abb3bb;
}

.formLoginGroup .checkbox-text {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-content {
  display: flex;
  align-items: center;
}

.checkbox-content input {
  accent-color: #e2ad2b;
  margin: 0 8px -2px 4px;
}

.checkbox-content span {
  font-family: "WorkSans", sans-serif;
  font-size: 14px;
}

.checkbox-text a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.checkbox-text a:hover {
  text-decoration: underline;
}

.formLoginGroup .button {
  margin-top: 20px;
}

.formLoginGroup .button input {
  border: none;
  background-color: #e2ad2b;
  color: white;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.signUpForm {
  text-align: center;
  margin-top: 20px;
}

.signUpForm a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.signUpForm span {
  color: #090909;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.signUpForm a:hover {
  text-decoration: underline;
}

/* CSS MOBILE */
@media (max-width: 650px) {
  .containerLogin {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .pageLogin {
    max-width: 125%;
    position: relative;
    padding: 15px;
    top: 80px;
    margin: -35px;
  }
  .CircularArea {
    position: relative;
    top: 250px;
    margin: -35px;
  }

  .contenairLoginForm {
    display: block;
    flex-direction: column;
  }

  .imageLogin img {
    display: none;
  }

  .formLogin {
    width: 100%;
    position: relative;
    left: 0px;
  }

  .infoLogin h2 {
    font-size: 18px;
  }
  .infoLogin span {
    font-size: 14px;
  }

  .formLoginGroup .button input {
    font-size: 14px;
  }
}

/* DEVICES AND TABLETTE */

@media (max-width: 960px) {
  .contenairLoginForm {
    display: block;
    flex-direction: column;
  }

  .imageLogin img {
    display: none;
  }
}

.containerCompte{

    max-width: 80%;
    margin: auto;
    /* background-color: red; */
    position: relative;
    top: 30px;
}

.section-My-Comte{
  display: flex;
  align-items: center;
  
}

.details-Compte{
  position: relative;
  width: 345px;
  height: 339px;
  padding: 32px 24px 63px 25px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: #f2f2f2;
  top:-290px

}

.details-Compte h2{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
}
.profile-user{
  display: flex;
  width: 100%;
  height: 70px;
  position: relative;
  top: 20px;

  
}

.profile-user .icon-profile-compte{
  color: #090909;
  position: relative;
  font-size: 18px;
  margin: 0 8px -2px 4px ;
} 

.profile-user span{
  font-family: 'WorkSans',sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}

.details-Compte .d span{
  color: #e2ad2b;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;

}

.Path {
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 32px 37px 19px;
  background-color: #e2ad2b;
}

/* CSS partie Compte */

.information-Compte{
  position: absolute;
  left: 80px;
}

.information-Compte h2{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
}

.information-Compte{
  position: relative;
  width: 60%;

}

.info-user{
  display: flex;
  width: 100%;
  height: 50px;
  position: relative;
  top: 20px;
}

.info-user .n-compte{
  color: #6e7684;
  position: relative;
  font-size: 16px;
  margin: 0 8px -2px 4px ;
  font-family: 'WorkSans',sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
} 

.info-user h4{
  font-family: 'WorkSans',sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
  
}

.information-Compte .m span{
  color: #e2ad2b;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;

}
.Path-info{
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 0px 0px 4px;
  background-color: #e2ad2b;
}

.uderline{
  position: relative;
  margin-top: 40px;
  background-color: #d8d8d8;
}


/* CSS Profile de reservation */

.profile-Reservation{
  position: relative;
  width: 100%;

}

.profile-Reservation h2{
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
  margin-top: 40px;
}


.info-reservation{
  display: flex;
  width: 100%;
  height: 50px;
  position: relative;
  top: 20px;
}

.info-reservation .n-reservation{
  color: #6e7684;
  position: relative;
  font-size: 16px;
  margin: 0 8px -2px 4px ;
  font-family: 'WorkSans',sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
} 

.info-reservation h4{
  font-family: 'WorkSans',sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
  
}

.profile-Reservation .r span{
  color: #e2ad2b;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;

}
.Path-r{
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 0px 0px 4px;
  background-color: #e2ad2b;
}

/* CSS Historique de reservation */

@media (max-width:650px){

  .containerCompte{
    position: relative;
    width: 100%;
    height: 100%;
    top: 130px;
   
    }

    .section-My-Comte{
      display: block;
      flex-direction: column;
      width: 100%;
      
    }

    .details-Compte{
      position: relative;
      top:0px;
      width: 115%;
      
    }

    .details-Compte h2{
      font-size: 14px;
    }

    .profile-user span{
      font-size: 14px;
    }

    .details-Compte .d span{
      font-size: 14px;
    }


    .information-Compte {
      position: relative;
      width: 100%;
      top: 20px;
      left: 20px;
  }

  .information-Compte h2{
    font-size: 14px;
  }

  .profile-Reservation h2 {
    font-size: 13px;
  }

  .profile-Reservation .r span, .information-Compte .m span{
    color: #e2ad2b;
    font-size: 12px;
  }

  .Path-info {
    display: none;

}

  .Path-r {
   display: none;
 
}

}


@media  (max-width:960px){

  .containerCompte{
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 15px;
    }


    .information-Compte{
      position: relative;
      left:30px;
    }


}

.ContainerEditProfil {
  max-width: 80%;
  margin: auto;
  position: relative;
  top: 30px;
}

.section-edit {
  display: flex;
  align-items: center;
}

.details-edit {
  position: relative;
  width: 345px;
  height: 339px;
  padding: 32px 24px 63px 25px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.24);
  background-color: #f2f2f2;
}

.details-edit h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
  color: #090909;
}
.profile-user-edit {
  display: flex;
  width: 100%;
  height: 70px;
  position: relative;
  top: 20px;
}

.profile-user-edit .icon-profile-compte-edit {
  color: #090909;
  position: relative;
  font-size: 18px;
  margin: 0 8px -2px 4px;
}

.profile-user-edit span {
  font-family: "WorkSans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #090909;
}

.details-edit .d span {
  color: #e2ad2b;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: normal;
}

.Path-edit {
  width: 65px;
  height: 5px;
  flex-grow: 0;
  margin: 16px 32px 37px 19px;
  background-color: #e2ad2b;
}

/* CCS FORMULAIRE EDIT */

.containerEdit-profil {
  width: 100%;
  position: relative;
  left: 40px;
}

.formEditGroup {
  position: relative;
  top: 10px;
}

.formInputEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoEdit-profil h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.infoEdit-profil {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.formInputEdit .inputFieldEdit {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputFieldEdit input {
  width: 95%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.formEditGroup .adress input {
  width: 98%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.inputFieldEdit input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

.formEditGroup .button {
  margin-top: 20px;
}

.formEditGroup .button input {
  border: none;
  background-color: #e2ad2b;
  color: white;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
  height: 50px;
  width: 98%;
}

.retourPageCompte {
  text-align: center;
  margin-top: 30px;
}

.retourPageCompte a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.retourPageCompte span {
  color: #090909;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.retourPageCompte a:hover {
  text-decoration: underline;
}

/* CSS MEDIA MOBILE */

@media (max-width: 650px) {
  .ContainerEditProfil {
    position: relative;
    width: 100%;
    height: 100%;
    top: 130px;
  }

  .section-edit {
    display: block;
    flex-direction: column;
    width: 100%;
  }

  .details-edit {
    position: relative;
    top: 0px;
    width: 115%;
  }

  .details-edit h2 {
    font-size: 14px;
  }

  /* CSS FORMULAIRE */

  .containerEdit-profil {
    width: 115%;
    position: relative;
    left: 0px;
  }

  .formInputEdit {
    display: block;
  }

  .inputFieldEdit input {
    width: 100%;
    height: 100%;
  }

  .formEditGroup .adress input {
    width: 100%;
    height: 100%;
  }

  .infoEdit-profil {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }

  .infoEdit-profil h2 {
    font-size: 14px;
  }

  .details-edit span {
    font-size: 14px;
  }

  .details-edit .d span {
    font-size: 14px;
  }

  .formEditGroup .button input {
    font-size: 14px;
  }
}

/* CSS MEDIA TABLETTE */

@media (max-width: 960px) {
  .ContainerEditProfil {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 15px;
  }

  .infoEdit-profil h2 {
    font-size: 14px;
  }
}




.formInputEdit .inputFieldEdit{
    margin-top: 10px;
    width: 100%;
    height: 50px;
    position: relative;
}

.inputFieldEdit select{
    width: 95%;
    height: 100%;
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px rgba(110, 118, 132, 0.33);
   
}

@media (max-width:650px){

    .inputFieldEdit select{
        width: 100%;
        height: 100%;      
    }

}
.pageRegister {
  display: flex;
  max-width: 70%;
  margin: auto;
  background-color: #f2f2f2;
  padding: 30px;
  position: relative;
  top: 50px;
}

.formInputGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 20px;
}

.formRegister {
  width: 100%;
  position: relative;
}

.infoRegister h2 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #090909;
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
}

.infoRegister {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pointerRegister {
  width: 20px;
  height: 8px;
  flex-grow: 0;
  background-color: #e2ad2b;
  align-items: center;
}

.formRegisterGroup {
  position: relative;
  top: 10px;
}

.formRegisterGroup .inputFieldRegister {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  position: relative;
}

.inputFieldRegister input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(110, 118, 132, 0.33);
}

.inputFieldRegister input:is(:focus, :valid) {
  border: solid 1px #e2ad2b;
}

.formRegisterGroup .button {
  margin-top: 40px;
}

.formRegisterGroup .button input {
  border: none;
  background-color: #e2ad2b;
  color: white;
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  cursor: pointer;
}

.YourPersonal {
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
  position: relative;
  top: 20px;
  left: 30px;
  color: #abb3bb;
}

.loginUpForm {
  text-align: center;
  margin-top: 30px;
}

.loginUpForm a {
  color: #e2ad2b;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.loginUpForm span {
  color: #090909;
  font-size: 14px;
  font-family: "WorkSans", sans-serif;
}

.loginUpForm a:hover {
  text-decoration: underline;
}

/* CSS MOBILE */
@media screen and (max-width: 650px) {
  .containerRegister {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .formInputGroup {
    display: block;
  }

  .pageRegister {
    max-width: 100%;
    grid-template-columns: 1fr;
    position: relative;
    padding: 15px;
    top: 140px;
  }

  .infoRegister {
    width: 100%;
    position: relative;
  }

  .infoRegister h2 {
    font-size: 19px;
  }

  .formRegisterGroup .button input {
    font-size: 14px;
  }

  .YourPersonal {
    left: 0px;
  }
}


.pagePwd{
    display: grid;
    max-width: 50%;
    grid-template-columns: repeat(1, 1fr);
    grid-gap:20px;
    margin: auto;
    background-color: #f2f2f2;
    padding: 30px;
    position: relative;
    top: 50px;
}


.formForget{
    width: 100%;
    position: relative;
}


.infoForget h2{
    font-family: 'Montserrat', sans-serif !important;
    font-size: 24px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #090909;
}


.formForgetGroup{
    position: relative;
    top: 10px;
}

.formForgetGroup .inputFieldForget{
    margin-top: 10px;
    width: 100%;
    height: 50px;
    position: relative;
}

.inputFieldForget input{
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 10px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 1px rgba(110, 118, 132, 0.33);
   
}

.inputFieldForget input:is(:focus, :valid){
    border:solid 1px #e2ad2b;
}

.formForgetGroup .button{
    margin-top: 20px;
}

.formForgetGroup .button input{
    border: none;
    background-color: #e2ad2b;
    color: white;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    cursor: pointer;
}

.signUpFormForget{
    text-align: center;
    margin-top: 20px;
}

.signUpFormForget a{
  color: #e2ad2b;
  font-size: 14px;
  font-family: 'WorkSans', sans-serif;
}

.signUpFormForget span{
    color: #090909;
    font-size: 14px;
    font-family: 'WorkSans', sans-serif;
  }

.signUpFormForget a:hover{
    text-decoration: underline;
    
  }

/* CSS MOBILE */
@media screen and (max-width: 650px) {

    .containerForgetPassword{
    position: relative;
    width: 100%;
    height: 100%;
    }


    .pagePwd{
        max-width: 100%;
        grid-template-columns: 1fr;
        position: relative;
        padding: 15px;
        top:140px;
    }

    .formForget{
        width: 100%;
        position: relative;
    }

    .infoForget h2{
        font-size: 19px;
    }
  
    .formForgetGroup .button input{
        font-size: 14px;
    }
}
.containerLogin {
  display: flex;
  grid-gap: 50px;
  gap: 50px;

  width: 80%;
  margin: auto;
  height: auto;
  margin-top: 2vh;
}
#carte-auto {
  flex: 2 1;
  display: flex;
  justify-content: center;
  padding-top: 4vh;
}
.assurance,
.options-sup,
.details-client,
.mode-payement {
  margin-top: 3vh;
  font-weight: bold;
}
.form-details {
  margin-bottom: 5vh;
}
.checkboxes {
  display: flex;
  justify-content: space-between;
}
.checkbox-droite {
  display: flex;
  flex-direction: column;
}
#partie-options-sup {
  margin-bottom: 5vh;
}
#carte-credit {
  width: 100%;
  height: 30vh;
  background-color: #282c30;
  margin-top: 5vh;
  color: #fff;
  padding: 30px;
}
.details-carte {
  display: flex;
  justify-content: space-between;
  margin-top: 8vh;
}
#total-carte {
  display: flex;
  justify-content: space-between;
  margin-top: 5vh;
  font-weight: bold;
}
#total-carte h5 {
  font-weight: bold;
  font-size: 25px;
}
#total-carte span {
  font-size: 18px;
}
#reservation-boutton {
  padding: 10px;
  background-color: #e2ad2b;
  text-align: center;
  max-width: 250px;
  margin-top: 3vh;
}
#reservation-boutton-partie {
  display: flex;
  justify-content: flex-end;
}
#reservation-boutton span {
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 640px) {
  .containerLogin {
    margin-top: 30vh;
  }
  .checkboxes {
    flex-direction: column;
  }
}
@media screen and (max-width: 992px) {
  .containerLogin {
    flex-direction: column;
  }
}

.formulaire-reservation {
  flex: 3 1;
  font-family: "Work Sans", sans-serif !important;
  padding: 0 !important;
}
.resever-boutton {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #fff;
}
.label-lieu-reservation,
.label-date-reservation,
.label-heure-reservation {
  font-weight: bold;
}
.form-reservation {
  margin: 0;
  padding: 0;
}
#boutton-coupon {
  padding: 12px 20px;
}

#total-carte h5 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .nom-proprietaire,
  .validation,
  .code-cvv {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .nom-proprietaire,
  .validation,
  .code-cvv {
    font-size: 10px !important;
  }
  .numero-carte,
  .numero {
    font-size: 15px;
  }
  #nom-proprietaire span,
  #validation span,
  #code-cvv {
    font-size: 10px !important;
  }
}
.nom-proprietaire,
.validation,
.code-cvv {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 400px) {
  .nom-proprietaire,
  .validation,
  .code-cvv {
    font-size: 7px !important;
  }
  .numero-carte,
  .numero {
    font-size: 8px;
  }
  #nom-proprietaire span,
  #validation span,
  #code-cvv {
    font-size: 7px !important;
  }
  .details-carte {
    margin-top: 4vh;
  }
  #carte-credit {
    height: 25vh;
  }
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

.partie-cvc {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

._2Jtxm._35Ago ._2kL0S {
  background-color: #e2ad2b;
  color: white;
  -webkit-animation: _JAh3L 400ms ease forwards;
          animation: _JAh3L 400ms ease forwards;
}
._hsN1w {
  background-color: #e2ad2b;
  border: none;
  color: white;
}
._hsN1w:hover {
  background-color: #e2ad2b;
  border: none;
  box-shadow: 0px 3px 6px 0 rgba(57, 124, 246, 0.3);
}

.content-103 {
  background: rgb(255, 255, 255);
  padding-bottom: 0px;
}
.contain_section {
  background-color: #f2f2f2;
  padding-bottom: 0px;
  padding: 20px;
  margin: 20px;
}

._download_apk {
  background-color: #ffffff;
  padding-bottom: 0px;
  padding: 20px;
  margin: 20px;
}

._download_apk p {
  width: 100%;
  line-height: 2.3;
}
.contain_section {
  background-color: white;
}
._image_section {
  width: 750px;
  height: 500px;
}
._paragraph h3 {
  font-family: "Montserrat";
}
._paragraph p {
  width: 80%;
  line-height: 2.3;
}
._download_paragraph {
  text-align: center;
  font-family: "Montserrat";
  padding: 15px;
}
.content-103 .sercies-title {
  text-align: center;
  padding: 50px 0;
}
.container_post {
  display: flex;
  padding: 20px;
  z-index: -1;
}
.social_ h4 {
  text-align: center;
  padding: 20px;
  font-family: "Montserrat";
}
._input_download {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-family: "Montserrat";
}
.Icon_download {
  font-size: 50px;
  position: relative;
  left: 50px;
  top: 10px;
}
.Icon_download_l,
.Icon_download {
  cursor: pointer;
}
.social_ {
}
.Icon_download_l {
  font-size: 50px;
  position: relative;
  left: 80px;
  top: 14px;
}
._social_network {
  display: flex;
  justify-content: center;
  font-size: 40px;
}
._social {
  padding: 30px;
}
._input_l {
  padding: 20px 60px;
  width: 33%;
  border: 3px solid orange;
  border-radius: 4px;
  position: relative;
  left: -10px;
}

._input_r {
  padding: 20px 80px;
  width: 30%;
  border: 3px solid orange;
  border-radius: 4px;
  position: relative;
  left: 10px;
}
.service-post {
  background-color: #f2f2f2;
  padding: 30px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  box-shadow: 0 5px 4px -4px rgba(0, 0, 0, 0.08);
}
.content-103 .service-content {
  position: relative;
  z-index: 13;
}
.content-103 .service-icon {
  margin: 10px 0 20px 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  border: 2px solid orange;
  margin-left: auto;
  margin-right: auto;
  line-height: 50px;
  transition: color, 250ms;
}
.content-103 .service-post:hover .service-icon {
  border-color: #fff;
}
.content-103 .service-icon {
  font-size: 28px;
  color: orange;
}
.content-103 .service-post:hover .service-icon {
  color: #fff;
}
.content-103 .service-title {
  font-family: "Montserrat";
  font-weight: normal;
  margin-bottom: 15px;
  padding-top: 20px;
  transition: color, 250ms;
}
.row_about h2 {
  font-size: 30px;
}

.service-description {
  font-family: none !important;
  font-size: 16px;
  font-weight: normal;

  margin-bottom: 0px;
  transition: color, 250ms;
  text-align: center;
  padding: 0px 0;
}
.content-103 .service-post:hover .service-title {
  color: #fff;
}
.content-103 .service-description {
  transition: color, 250ms;
}
.content-103 .service-post:hover .service-description {
  color: #fff;
}
._download_apk p {
  line-height: 2;
}

.content-103 .service-hover {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;

  z-index: 11;
  width: 100%;
  height: 0px;
  background: orange;
  transition: width, 250ms;
}
/*hover-background-color effect*/
.content-103 .service-post:hover .service-hover {
  height: 100%;
}
.contain_section {
  display: flex;
}
@media screen and (max-width: 420px) {
  .container_post {
    display: block;
  }
  ._image,
  ._image_section {
    display: none;
  }
  .contain_section {
    position: relative;
    margin-top: 230px;
  }
  ._paragraph p {
    width: 80%;
    font-size: 20px;
    line-height: 1.3;
  }

  .row_about h2 {
    font-size: 16px;
  }
  .row_about {
    position: relative;
    top: 180px;
  }
  .social_ {
    position: relative;
    top: 20px;
    font-size: 14px;
    background-color: #f2f2f2;
    padding: 30px;
  }
  ._input_download {
    display: block;
  }
  ._input_l {
    display: none;
  }

  ._input_r {
    display: none;
  }
  .Icon_download_l {
    font-size: 27px;
    left: 30%;
    margin-left: 90px;
  }
  .Icon_download {
    font-size: 30px;
    left: 30%;
    margin-left: -13px;
  }
}

#details_voitures {
  width: 80%;
  margin: auto;
}

#section-image {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  flex-wrap: wrap;
}
.entete-details {
  background-color: #fff;
  border: 1px solid rgba(204, 204, 204, 0.235);
  height: 10vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  flex-wrap: wrap;
}
.details-image {
  height: 50vh;
  width: 100%;
}
.entete-details > h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
}
.rating-entete {
  padding: 1vh 0;
}
.details-entete {
  display: flex;
  grid-gap: 11px;
  gap: 11px;
  flex-wrap: wrap;
}
.details-entete div:nth-child(1) {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.details-entete div:nth-child(2) {
  background-color: #e2ad2b;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  flex-wrap: wrap;
}
.details-entete div span:first-child {
  color: #e2ad2b;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
}
#bouttons-reserves {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  position: relative;
  bottom: 5vh;
  justify-content: center;
  flex-wrap: wrap;
}
.choisir-voiture {
  width: 40%;
  height: 8vh;
  background-color: #e2ad2b;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  cursor: pointer;
  flex-wrap: wrap;
}
.partie-details-voitures {
  display: flex;
  flex-direction: column;
  padding: 0 20px 30px 20px;
  line-height: 5vh;
  flex-wrap: wrap;
}
#sous-groupe {
  display: flex;
  flex: 1 1;
}
#section-groupe-details-voiture {
  display: flex;

  flex-wrap: wrap;
}
.details-des-voiture span:nth-child(1) {
  color: #6e7684;
  font-size: 16px;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
}
.details-des-voiture span:nth-child(2),
.rating-partie {
  font-family: "Work Sans", sans-serif;
}
.rating-partie {
  margin-top: 5vh;
}
.infos-circle {
  color: #e2ad2b;
}
.details-location {
  font-family: "Work Sans", sans-serif;
  padding: 35px 20px;
}
.details-location h5 {
  font-weight: bold;
}
.details-location-droite,
.details-location-gauche {
  line-height: 6vh;
}
.details-location-partie {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
}

.reviews-partie {
  padding: 35px 20px;
}
.reviews-partie h2 {
  font-family: "Montserrat", sans-serif;
}
.reviews-ensemble {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}
.titre-reviews {
  font-family: "Montserrat", cursive;
}
.reviews-texte {
  font-family: "Work Sans", sans-serif;
  margin-top: 2vh;
}
#groupe-details {
  flex: 2 1;
  line-height: 6vh;
}
.reviews-images {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
  margin-top: 3vh;
  flex-wrap: wrap;
}
.reviews-images div {
  width: 250px;
  height: 20vh;
  background-color: #ccc;
}
.reviews {
  margin-bottom: 2vh;
  margin-top: 2vh;
}
.voir-commentaires {
  display: flex;
  color: #e2ad2b;
  grid-gap: 5px;
  gap: 5px;
  padding: 5px 20px;
  font-family: "Montserrat", sans-serif;
  justify-content: flex-end;
  font-size: 10px;
}
@media screen and (max-width: 1200px) {
  #section-groupe-details-voiture {
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  .details-image {
    height: 30vh !important;
  }
  .details-location-partie {
    grid-gap: 0px;
    gap: 0px;
  }
  #sous-groupe {
    grid-gap: 0px;
    gap: 0px;
    flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  #details_voitures {
    width: 100%;
  }
  .voir-commentaires {
    justify-content: flex-start !important;
    margin-bottom: 2vh;
  }

  .reviews {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .reviews-texte {
    margin: 2vh 0;
    width: 100%;
  }
  .reviews-images {
    justify-content: center;
  }
  .reviews-partie h2 {
    font-size: 16px;
  }

  .entete-details h3 {
    font-size: 12px;
  }
  .rating-entete {
    font-size: 13px;
  }
  .choisir-voiture {
    font-size: 13px;
  }
}
@media screen and (min-width: 992px) {
  .reviews-texte {
    width: 60%;
  }
}

.MuiMobileStepper-dot {
  background-color: #e2ad2b !important;
}
@media screen and (max-width: 650px) {
  .image-des-details {
    height: 30vh !important;
  }
}

.form-Avis {
  width: 80%;
  display: flex;
  flex-direction: column;
  grid-gap: 2vh;
  gap: 2vh;
  margin-top: 3vh;
  font-family: "Work Sans", sans-serif;
}
.section-avis-voiture {
  padding: 0 20px;
}
.error-paragraph {
  margin-bottom: 2.5vh;
}
.avis-info {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.titreform {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .form-Avis {
    width: 100% !important;
    padding: 0 15px;
  }
  .rating-partie {
    padding: 0 15px;
  }
  .avis-info {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
  }
}
.formulaire-avis,
.soummettre {
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 992px) {
  .formulaire-avis {
    font-size: 16px;
    padding: 0 15px;
  }
}

.dialog-boite {
  max-width: 800px !important;
  overflow-x: hidden;
}

.containerDetails {
  display: flex;
  justify-content: center;
  grid-gap: 100px;
  gap: 100px;
  width: 98%;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
}
.filtres-details {
  flex: 1 1;
}
.details-voiture {
  flex: 3 1;
}
@media screen and (max-width: 992px) {
  .containerDetails {
    flex-direction: column;
  }
  .filtres-details {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .containerDetails {
    margin-top: 30vh;
  }
}

#section-collection {
  display: flex;
  justify-content: center;
  width: 98%;
  grid-gap: 30px;
  gap: 30px;
  height: auto;
  margin: auto;
  flex-wrap: wrap;
}
#collection-pagination {
  display: flex;
  flex-direction: column;
}
#collections {
  display: flex;
  flex-direction: column;
  grid-gap: 3vh;
  gap: 3vh;
  height: auto;
  flex: 3 1;
  width: 100%;
}
#filtres {
  height: auto;
  width: 300px;
}

/*Responsivité*/

@media screen and (max-width: 640px) {
  #section-collection2 {
    margin-top: 30vh;
  }
}

.image_historique {
  width: 100%;
  max-width: 200px;
}

.affiche2-mobile {
  display: none;
}
@media screen and (max-width: 640px) {
  .affiche2-mobile {
    display: block;
    margin-top: 30vh;
  }
}
@media screen and (max-width: 700px) {
  .affiche2-mobile {
    display: block;
  }
  .table-affichage {
    display: none;
  }
}

.image_voiture {
  width: 100%;
  max-width: 300px;
}
.affiche2 {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 5vh;
  padding: 0 0 0 10px;
}
.rangement span:nth-child(1) {
  color: #8a8886;
}
.rangement {
  margin-top: 2vh;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

@media screen and (max-width: 650px) {
  .ContainerHistoriqueReservation {
    margin-top: 30vh;
  }
}

