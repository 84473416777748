.form-Avis {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-top: 3vh;
  font-family: "Work Sans", sans-serif;
}
.section-avis-voiture {
  padding: 0 20px;
}
.error-paragraph {
  margin-bottom: 2.5vh;
}
.avis-info {
  display: flex;
  gap: 20px;
}
.titreform {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
}
@media screen and (max-width: 768px) {
  .form-Avis {
    width: 100% !important;
    padding: 0 15px;
  }
  .rating-partie {
    padding: 0 15px;
  }
  .avis-info {
    flex-direction: column;
    gap: 0;
  }
}
.formulaire-avis,
.soummettre {
  font-family: "Montserrat", sans-serif;
}
@media screen and (max-width: 992px) {
  .formulaire-avis {
    font-size: 16px;
    padding: 0 15px;
  }
}
