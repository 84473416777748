.formulaire-reservation {
  flex: 3;
  font-family: "Work Sans", sans-serif !important;
  padding: 0 !important;
}
.resever-boutton {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px;
  color: #fff;
}
.label-lieu-reservation,
.label-date-reservation,
.label-heure-reservation {
  font-weight: bold;
}
.form-reservation {
  margin: 0;
  padding: 0;
}
#boutton-coupon {
  padding: 12px 20px;
}

#total-carte h5 {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .nom-proprietaire,
  .validation,
  .code-cvv {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .nom-proprietaire,
  .validation,
  .code-cvv {
    font-size: 10px !important;
  }
  .numero-carte,
  .numero {
    font-size: 15px;
  }
  #nom-proprietaire span,
  #validation span,
  #code-cvv {
    font-size: 10px !important;
  }
}
.nom-proprietaire,
.validation,
.code-cvv {
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 400px) {
  .nom-proprietaire,
  .validation,
  .code-cvv {
    font-size: 7px !important;
  }
  .numero-carte,
  .numero {
    font-size: 8px;
  }
  #nom-proprietaire span,
  #validation span,
  #code-cvv {
    font-size: 7px !important;
  }
  .details-carte {
    margin-top: 4vh;
  }
  #carte-credit {
    height: 25vh;
  }
}
